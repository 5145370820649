<template>
  <div>
    <vx-card>
      <vs-table
        v-model="selectedDemand"
        :data="demandlist"
        @dblSelection="doubleSelection"
      >
        <template slot="header">
          <vs-input
            class="my-5"
            type="number"
            v-model="demandID"
            label-placeholder="شماره درخواست"
            @keyup.enter="getDemandID(demandID)"
          >
          </vs-input>
        </template>
        <template slot="thead">
          <vs-th> شماره درخواست </vs-th>
          <vs-th> دسته بندی </vs-th>
          <vs-th> وضعیت </vs-th>
          <vs-th> تاریخ درخواست </vs-th>
          <vs-th> شماره کاربر </vs-th>
          <vs-th> توضیحات </vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr
            :state="
              tr.status === 1
                ? 'success'
                : tr.status === 2
                ? 'warning'
                : tr.status === 3
                ? 'primary'
                : ''
            "
            :data="tr"
            :key="indextr"
            v-for="(tr, indextr) in data"
          >
            <vs-td>{{ data[indextr].id }}</vs-td>
            <vs-td>{{ data[indextr].category }}</vs-td>
            <vs-td>{{ data[indextr].status_string }}</vs-td>
            <vs-td>{{ data[indextr].created_at }}</vs-td>
            <vs-td>{{ data[indextr].user_id }}</vs-td>
            <vs-td
              ><div
                class="overflow-hidden max-h-small max-w-sm"
                v-html="data[indextr].user_description"
              ></div
            ></vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-divider style="height: 100px"></vs-divider>
      <vue-json-pretty :data="selectedDemand"> </vue-json-pretty>
      <vs-popup :active.sync="answertodemandpopup" title=" پاسخ به درخواست ">
        <vs-textarea
          placeholder=" توضیحات ادمین "
          v-model="admin_description"
        ></vs-textarea>
        <div>
          <vs-input
            type="number"
            class="w-full my-1"
            placeholder="مقدار"
            v-model="amount"
          ></vs-input>
          <vs-input
            class="w-full my-1"
            placeholder=" بازه زمانی "
            type="number"
            v-model="payment_period"
          ></vs-input>
          <vs-input
            class="w-full"
            placeholder="مقدار کل"
            type="number"
            v-model="finalamount"
          >
          </vs-input>
        </div>
        <vs-divider></vs-divider>
        <div></div>
        <!-- <vue-json-pretty :data="selectedDemand2"></vue-json-pretty> -->
      </vs-popup>
      <vs-pagination
        :total="last_page"
        v-model="current_page"
      ></vs-pagination>
    </vx-card>
  </div>
</template>
<script>
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
export default {
  components: {
    VueJsonPretty,
  },
  data() {
    return {
      demandlist: [],
      selectedDemand: null,
      demandID: null,
      // POPUP ==================
      answertodemandpopup: false,
      // POPUP ==================
      // UPDATE DEMAND VAR ====================
      admin_description: "",
      payment_period: "",
      amount: null,
      finalamount: null,
      // UPDATE DEMAND VAR ====================
      // Pagination =======================
      current_page: 1,
      last_page: 0,
      // Pagination =======================
    };
  },
  computed: {
    verifyPaymentPeriod() {
      if (this.payment_period < 13 && this.payment_period > -1) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    current_page: function () {
      this.getDemandlist();
    },
  },
  methods: {
    doubleSelection() {
      this.$router.push(`/service/${this.selectedDemand.id}`);
    },
    getDemandlist() {
      this.demandlist = [];
      this.$vs.loading();
      this.$http
        .get(`admin/service?page=${this.current_page}`)
        .then((res) => {
          this.$vs.loading.close();
          console.log(res);
          // console.log(...res.data.params.data);
          // console.log(this.last_page);
          this.last_page = res.data.params.last_page;
          this.demandlist = res.data.params.data;
        })
        .catch((err) => {
          this.$vs.loading.close();
          console.log(err);
        });
    },
    getDemandID(id) {
      // console.log(id);
      this.$http
        // .get(`admin/service/${id}`)
        .get(`admin/service/${id}`)
        .then((res) => {
          // console.log(res);
          this.demandlist = [res.data.params];
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getDemandlist();
  },
};
</script>
